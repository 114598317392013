html {
    scroll-behavior: smooth;
}

/**
 * Page content
 */
.skip-nav-link {
    position: absolute;
    transform: translateY(-120%);
}

.skip-nav-link:focus {
    transform: translateY(0);
}

#page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: $emph-font-size;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: var(--on-surface);
    padding-left: 0.3em;
}

.post-link {
    display: block;
    font-size: $emph-font-size;
}

a[href*="play.google.com/store"] img {
    height: 4em;
}

/**
 * Posts
 */
.post-content {
    max-width: 600px;
}

.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 24px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 1.3em;
    }

    h3 {
        font-size: 1.2em;
    }

    h4 {
        font-size: 1.1em;
    }
}



/**
 * Category & Tags
 */
@mixin prefixwith($character) {
  display: inline-block;
    margin-right:12px;
    font-style:italic;

    &:before {
        content:$character;
    }
}

li.category {
    @include prefixwith("/");
}


li.tag {
    @include prefixwith("#");
}

ul.ptags {
    > li {
        display: inline-block;
        margin-right: 6px;
        color: var(--on-surface);
    }

    > li:after {
        content: ",";
    }

    > li:last-child:after {
        content: "";
    }
}



/**
 * Icons
 */
.icon {
    > svg, > img {
        display: inline-block;
        width: 1em;
        vertical-align: middle;

        path {
            fill: var(--on-surface);
        }
    }
}

acronym, smallcaps {
    font-variant: small-caps;
}


/**
 * Keyboard Shortcuts
 */
kbd {
    border: 1px var(--on-surface) solid;
    border-bottom-width: 2px;
    border-radius: 3px;
    padding: 0 2px 0 2px;
    background: var(--on-surface);
}

.iframe-container {
  overflow: hidden;
  padding-top: 100%;
  position: relative;
}

.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}
