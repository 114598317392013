$brand-color: #0093EE !default;

@media (prefers-color-scheme: light) {
    :root {
        --text-color: #111111;
        --background-color: #fdfdfd;

        --surface: #c0c0c0;
        --on-surface: #414141;
    }
}


@media (prefers-color-scheme: dark) {
    :root {
        --text-color: #fafafa;
        --background-color: #212121;

        --surface: #414141;
        --on-surface: #c0c0c0;
    }

    .post-content img[src$=svg] {
        filter: #{ "invert();" }
    }
}
