/**
 * Site header
 */
.site-header {
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    & {
        color: var(--on-surface);
    }
}

.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
        margin: 6px;
    }

    .page-link {
        color: val(--text-color);
        line-height: $base-line-height;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 20px;
        }
    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: var(--background-color);
        border: 1px solid var(--on-surface);
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            background: url(/assets/menu.svg) no-repeat;
            background: url(/assets/menu.svg) no-repeat;
            background-position: right 10px top 5px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: var(--on-surface);
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}
