.photo-list {
	display: flex;
	flex-wrap: wrap;

	figure {
		position: relative;
		float: left;

		img {
			clear: left;

			margin-bottom: 1px;
			margin-right: 16px;

			object-fit: cover;
			width: 180px;
			height: 180px;
		}

		figcaption {
			color: white;
			background-color: rgba(0, 0, 0, 0.7);
			font-size: 8pt;
			margin-bottom: 1px;
			margin-right: 16px;

			display: block;
			float: left;

			position: absolute;
			bottom: 0;
		}
	}
}
