/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}



/**
 * Basic styling
 */
body {
    font-size: $base-font-size;
    font-family: $base-font-family;
    color: var(--text-color);
    background-color: var(--background-color);
    line-height: $base-line-height;
    font-weight: 300;
    -webkit-text-size-adjust: 100%;
}


big {
    font-size: 350%;
    font-weight: bold;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p:not(.subscribe), blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: calc($spacing-unit / 2);
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:hover {
        color: color.adjust($brand-color, $lightness: -15%);
        text-decoration: underline;
    }
}



/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}



/**
 * Abbreviations
 */
abbr, acronym {
    text-decoration: none;
    border-bottom:1px dashed;
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Blockquotes
 */
blockquote {
    padding-left: calc($spacing-unit / 2);
    font-size: $emph-font-size;
    letter-spacing: -1px;
    font-style: italic;
    color: var(--on-surface);
    border-left: 4px solid $brand-color;

    > :last-child {
        margin-bottom: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width:         calc(800px - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width:         calc(800px - (#{$spacing-unit}));
        padding-right: calc($spacing-unit / 2);
        padding-left: calc($spacing-unit / 2);
    }
}



/**
 * Clearfix
 */
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
